import { Controller } from "@hotwired/stimulus"

// Import TinyMCE
import tinymce from 'tinymce/tinymce'
import 'tinymce/models/dom'

// Default icons are required
import 'tinymce/icons/default';

// A theme is also required
import 'tinymce/themes/silver';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/wordcount';

export default class extends Controller {
  connect () {
    this.tinymce = tinymce.init({
      target: this.element,
      relative_urls: false,
      menubar: false,
      height: 250,
      plugins: 'autolink link lists wordcount visualchars visualblocks',
      toolbar: 'bold italic | link | bullist',
      link_rel_list: [
        { title: 'None', value: '' },
        { title: 'No Referrer', value: 'noreferrer' },
        { title: 'External Link', value: 'external' },
        { title: 'Nofollow', value: 'nofollow' }
      ],
      removeformat : [
        { selector : '*', attributes : ['style', 'class'], split : false, expand : false, deep : true }
      ],
      visualblocks_default_state: true,
      visualchars_default_state: true,
      end_container_on_empty_block: true,
      paste_as_text: true,
      valid_elements : "a[href|target|title|class<link|id|rel<nofollow]," +
                        "p,strong/b,u,i/em,br,ul,li,"
    })
  }

  disconnect () {
    tinymce.remove()
  }
}
